import React from 'react';

import Layout from '../components/Shared/Layout';
import Cloud from '../components/Connect/Cloud/Cloud';

export default () => (
  <Layout isHomepage>
    <Cloud />
  </Layout>
);

import React from 'react';
import '../../../assets/css/zero-to-one.css';

function Header() {
  return (
    <main className="main ">
      <div className="container-fluid">
        <div className="main-padding heading-container-padding row d-flex justify-content-center align-items-center">
          <div className="col-12 col-md-10 col-lg-12 content-slide-top-container padding-top-heading">
            <div className="description latoRegular">
              <p className="heading-description-white">
                The Tintash Cloud Studio has been working with scale-ups on their GCP and AWS needs
                since 2016. Our expertise includes visualization dashboards, SaaS platforms,
                cloud-to-cloud migrations, and automated pipeline setups. Our solutions are designed
                for scalability and efficiency, delivered by our high quality offshore team.
                <br />
                <br />
                Our present focus is on growing AWS + Gen AI competencies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Header;

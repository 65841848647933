import React from 'react';
import '../../../assets/css/zero-to-one.css';
import deisgnpro from '../../../assets/images/zero-to-one/design-pro.png';
import theplayerslounge from '../../../assets/images/zero-to-one/player-lounge.png';
import raven from '../../../assets/images/zero-to-one/raven.png';
import cntxt from '../../../assets/images/zero-to-one/cntxt.png';

function FeaturedClient() {
  return (
    <div className="featured-client">
      <div className="logos-container d-flex justify-content-center">
        <div className="row d-flex justify-content-center align-items-center w-100 mw-100">
          <div className="col-6 col-md-3 d-flex justify-content-center">
            <img
              src={deisgnpro}
              alt="DesignPro"
              className="img-fluid"
              style={{
                transform: 'scale(1.3)',
              }}
            />
          </div>
          <div className="col-6 col-md-3 d-flex justify-content-center">
            <img src={theplayerslounge} alt="The Players' Lounge" className="img-fluid" />
          </div>
          <div className="col-6 col-md-3 d-flex justify-content-center">
            <img src={raven} alt="Raven" className="img-fluid" />
          </div>
          <div className="col-6 col-md-3 d-flex justify-content-center">
            <img src={cntxt} alt="Cntxt" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedClient;

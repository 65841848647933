import React, {useEffect, useState} from 'react';

const StudioNavbar = ({studioName}) => {
  // const [isNavbarHidden, setIsNavbarHidden] = useState(false);
  // const [lastScrollY, setLastScrollY] = useState(0);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollY = window.scrollY;

  //     if (currentScrollY > 10) {
  //       // Scrolling down and scrolled more than 100px (to prevent jump at top)
  //       setIsNavbarHidden(true);
  //     } else if (currentScrollY <= 50) {
  //       // Scrolling up or less than 50px from the top
  //       setIsNavbarHidden(false);
  //     }

  //     setLastScrollY(currentScrollY);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [lastScrollY]);

  return (
    // <div
    //   className="heading-link-studio"
    //   style={{top: isNavbarHidden ? `0px` : 'var(--main-navbar-height, 3.5rem)'}}
    // >
    <div className="heading-link-studio" style={{top: 'var(--main-navbar-height, 3.4rem)'}}>
      <span>{studioName}</span>
      <div>{/* <a href="#thinking">Thinking & Mentions</a> */}</div>
    </div>
  );
};

export default StudioNavbar;

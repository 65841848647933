import React from 'react';
import {Helmet} from 'react-helmet';
import Header from './Header';
import FeaturedClient from './FeaturedClient';
import FeaturedPortfolios from './FeaturedPortfolios';
import StudioNavbar from '../../Shared/StudioNavbar';

function Cloud() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cloud | Tintash</title>
      </Helmet>
      <StudioNavbar studioName="Cloud Studio" />
      <Header />
      <FeaturedClient />
      <FeaturedPortfolios />
    </>
  );
}

export default Cloud;
